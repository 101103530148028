import * as React from 'react';
import { Checkbox, CheckboxOptionType } from 'antd';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { FiltersActionKind, useFilters } from '../../../../context/filters-context';
import { useDocumentSize } from '../../../../hooks/useDocumentSize';
import { useTheme } from '../../../../context/theme-context';

const optumOptions: CheckboxOptionType[] = [
  { label: 'In Network', value: 'in-network' },
  { label: 'Out of Network', value: 'allowed-amounts' },
  { label: 'Prescription Drugs', value: 'prescription-drugs' },
  { label: 'Index', value: 'index' },
];

const uhcOptions: CheckboxOptionType[] = [
  { label: 'In Network', value: 'in-network' },
  { label: 'Out of Network', value: 'allowed-amounts' },
  { label: 'Index', value: 'index' },
];

export const FileTypeCheckboxes = (): JSX.Element => {
  const theme: string = useTheme();
  const {
    filters: { fileTypes },
    dispatch,
  } = useFilters();
  const { isSmallDocument } = useDocumentSize();

  React.useEffect(() => {
    if (theme === 'uhc') {
      dispatch({
        type: FiltersActionKind.setFileTypes,
        fileTypes: ['index'],
      });
    }
  }, []);

  const onChange = (checkedValues: CheckboxValueType[]): void => {
    dispatch({
      type: FiltersActionKind.setFileTypes,
      fileTypes: checkedValues as string[],
    });
  };

  return (
    <Checkbox.Group
      options={theme === 'optum' ? optumOptions : uhcOptions}
      value={fileTypes}
      onChange={onChange}
      style={{ textAlign: isSmallDocument ? 'center' : 'left' }}
    />
  );
};
