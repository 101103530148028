import React from 'react';
import ReactDOM from 'react-dom/client';
import { App } from './components/App/App';
import { BrowserRouter as Router } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import reportWebVitals from './reportWebVitals';
import './index.css';

if (process.env.REACT_APP_BRAND_TYPE === 'optum') document.title = 'Optum Transparency in Coverage';

const faviconPath: string = process.env.REACT_APP_BRAND_TYPE !== 'optum' ? 'favicon_uhc.png' : 'favicon-optum.png';
const favicon: HTMLLinkElement = document.querySelector('link[rel=icon]') as HTMLLinkElement;

favicon.href = faviconPath;

const queryClient: QueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 60 * 60 * 1000,
      refetchOnWindowFocus: false,
      retry: 2,
    },
  },
});

// eslint-disable-next-line @typescript-eslint/typedef
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Router>
        <App />
      </Router>
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
