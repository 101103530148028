import * as React from 'react';

/**
 * This file contains the context definition for Filters.
 * We store two filters in the context, a searchTerm and a list of fileTypes.
 * Context can be consumed by children of the exported FiltersProvider component by using the
 * useFilters hook.
 */

interface IFiltersProviderProps {
  children: React.ReactNode;
}

// Interface defining the shape and type of our context state
interface IFiltersState {
  fileTypes: string[];
  searchTerm: string;
}

enum FiltersActionKind {
  setFileTypes = 'SET_FILE_TYPES',
  setSearchTerm = 'SET_SEARCH_TERM',
}

type FiltersAction =
  | { type: FiltersActionKind.setFileTypes; fileTypes: string[] }
  | { type: FiltersActionKind.setSearchTerm; searchTerm: string };

// Interface defining the type of the FiltersContext value
interface IFiltersContext {
  filters: IFiltersState;
  dispatch: React.Dispatch<FiltersAction>;
}

const initialFiltersState: IFiltersState = {
  fileTypes: [],
  searchTerm: '',
};

// The reducer function for our FiltersContext state
function filtersReducer(state: IFiltersState, action: FiltersAction): IFiltersState {
  switch (action.type) {
    case FiltersActionKind.setFileTypes:
      return {
        ...state,
        fileTypes: action.fileTypes,
      };
    case FiltersActionKind.setSearchTerm:
      return {
        ...state,
        searchTerm: action.searchTerm,
      };
    default:
      throw new Error();
  }
}

// The FiltersContext has an initial vaue of undefined. We populate a meaningful value in the FiltersProvider component.
const FiltersContext: React.Context<IFiltersContext | undefined> = React.createContext<IFiltersContext | undefined>(
  undefined
);

/**
 * A custom Provider component wrapping the default FiltersContext.Provider component
 * This is used to create a meaningful, dynamic context value
 */
function FiltersProvider({ children }: IFiltersProviderProps): JSX.Element {
  /**
   * We create our context value with a useReducer hook.
   * The return of the useReducer hook is a tuple containing the context value (filters), and a function
   * to update it (dispatch).
   */
  const [filters, dispatch] = React.useReducer(filtersReducer, initialFiltersState);

  // NOTE: you *might* need to memoize this value
  // Learn more in http://kcd.im/optimize-context
  const value: IFiltersContext = { filters, dispatch };
  return <FiltersContext.Provider value={value}>{children}</FiltersContext.Provider>;
}

/**
 * A custom hook to consume the FiltersContext.
 * It can only be used within a child of the FiltersProvider component
 * @returns The FiltersContext value
 */
function useFilters(): IFiltersContext {
  const context: IFiltersContext | undefined = React.useContext(FiltersContext);
  if (context === undefined) {
    throw new Error('useFilters must be used within a FiltersProvider');
  }
  return context;
}

export { FiltersActionKind, FiltersProvider, useFilters };
