import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

/**
 * This file contains the code for creating an app insights instance using the app insights react plugin.
 * App Insights is used for user + event tracking on the website.
 * The connection string is configured as an environment variable at build time
 */

const reactPlugin: ReactPlugin = new ReactPlugin();
const appInsights: ApplicationInsights = new ApplicationInsights({
  config: {
    connectionString: process.env.REACT_APP_AI_CONNECTION_STRING,
    enableAutoRouteTracking: true,
    maxBatchInterval: 0,
    disableFetchTracking: false,
    extensions: [reactPlugin],
  },
});

export { reactPlugin };
export { appInsights };
