import * as React from 'react';
import { Alert } from 'antd';
import { FlagFilled } from '@ant-design/icons';
import { useTheme } from '../../../context/theme-context';
import { getDisclaimer } from './disclaimerConstants';

export const Disclaimer = (): JSX.Element => {
  const theme: string = useTheme();

  const disclaimer: JSX.Element = getDisclaimer(theme);

  return <Alert closable banner description={disclaimer} showIcon icon={<FlagFilled />} type='info' />;
};
