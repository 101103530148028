import * as React from 'react';

/**
 * This file contains the context definition for the global theme.
 * The theme is used to conditionally add/remove features and functionality to the site since both the Optum
 * and UHC public sites are built from the same code.
 */

interface IThemeProviderProps {
  children: React.ReactNode;
}

// The ThemeContext has an initial vaue of undefined. We populate a meaningful value in the ThemeProvider component.
const ThemeContext: React.Context<string | undefined> = React.createContext<string | undefined>(undefined);

function ThemeProvider({ children }: IThemeProviderProps): JSX.Element {
  // The theme value comes from an environment variable configured at build time
  const [theme, setTheme] = React.useState<string>(process.env.REACT_APP_BRAND_TYPE || 'uhc');

  React.useEffect(() => {
    setTheme(process.env.REACT_APP_BRAND_TYPE || 'uhc');
  }, [process.env.REACT_APP_BRAND_TYPE]);
  // NOTE: you *might* need to memoize this value
  // Learn more in http://kcd.im/optimize-context
  return <ThemeContext.Provider value={theme}>{children}</ThemeContext.Provider>;
}

/**
 * A custom hook to consume the ThemeContext.
 * It can only be used within a child of the ThemeProvider component
 * @returns The ThemeContext value
 */
function useTheme(): string {
  const context: string | undefined = React.useContext(ThemeContext);
  if (context === undefined) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
}

export { ThemeProvider, useTheme };
