import React from 'react';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { Layout, Space } from 'antd';
import { AppHeader } from '../shared/AppHeader';
import { Disclaimer } from '../shared/Disclaimer';
import { Filters } from '../shared/Filters';
import { FileList } from '../shared/FileList';
import { AppFooter } from '../shared/AppFooter';
import { FiltersProvider } from '../../context/filters-context';
import { ThemeProvider } from '../../context/theme-context';
import { useIncompatibleBrowserView } from '../../hooks/useIncompatibleBrowserView';
import './App.less';
import { appInsights, reactPlugin } from '../../helpers/analytics';

const { Content } = Layout;

appInsights.loadAppInsights();
appInsights.trackPageView();

export const App = (): JSX.Element => {
  const { IncompatibleBrowserViewProvider } = useIncompatibleBrowserView();

  return (
    // Provides the Azure application insights instance to the app
    <AppInsightsContext.Provider value={reactPlugin}>
      {/* If a compatible browser is used, render the children, otherwise an error page is displayed. See useIncompatibleBrowserView */}
      <IncompatibleBrowserViewProvider>
        {/* Provides the theme setting to the application  */}
        <ThemeProvider>
          {/* Provides the search and filter criteria to child components (Optum ONLY) */}
          <FiltersProvider>
            <Layout data-test-id='layout' style={{ minHeight: '100vh', maxHeight: '100vh' }}>
              <AppHeader />
              <Space size={16} direction='vertical' style={{ margin: '8px 16px' }}>
                <Disclaimer />
                <Filters />
              </Space>
              <Content style={{ margin: 16, overflow: 'auto' }}>
                <FileList />
              </Content>
              <AppFooter />
            </Layout>
          </FiltersProvider>
        </ThemeProvider>
      </IncompatibleBrowserViewProvider>
    </AppInsightsContext.Provider>
  );
};
