import { message } from 'antd';

export const getErrorMessage = (error: unknown): string => {
  if (error instanceof Error) {
    return error.message;
  }
  return String(error);
};

export const displayErrorMessage = (errorMessage: string): void => {
  message.error(errorMessage, 10);
};
