import * as React from 'react';
import { BrowserView, browserName } from 'react-device-detect';
import { Result, Button } from 'antd';
import { ReactPlugin, useAppInsightsContext } from '@microsoft/applicationinsights-react-js';

/**
 * Our website is not available on Internet Explorer. If IE is used, we render an error message.
 */

// a map from the detected browser name to the official browser name, for display purposes only
const browserMap: Map<string, string> = new Map([
  ['IE', 'Internet Explorer'],
  ['Chrome', 'Google Chrome'],
  ['Edge', 'Microsoft Edge'],
  ['Firefox', 'Mozilla Firefox'],
  ['Opera', 'Opera'],
  ['Safari', 'Safari'],
]);

// These browsers should always be supported by our applicatons
// If you use any of these in the incompatibleBrowsers param of useIncompatibleBrowserViewProvider, they will be ignored
const requiredSupportBrowsers: string[] = [`Chrome`, `Edge`, 'Firefox'];

// by default, we only disable use in IE
const defaultIncompatibleBrowsers: string[] = ['IE'];

interface IIncompatibleBrowserResultProps {
  incompatibleBrowsers: Array<string>;
}

interface IIncompatibleBrowserViewProviderReturnType {
  IncompatibleBrowserViewProvider: (props: IIncompatibleBrowserViewProviderProps) => JSX.Element;
}

interface IIncompatibleBrowserViewProviderProps {
  children?: React.ReactNode;
  incompatibleBrowsers?: Array<string>;
}

const IncompatibleBrowserResult = ({ incompatibleBrowsers }: IIncompatibleBrowserResultProps): JSX.Element => {
  const appInsights: ReactPlugin = useAppInsightsContext();

  // creates a comma separated list of incompatible browsers
  const incompatibleBrowserNames: string = incompatibleBrowsers
    .map((browser: string) => browserMap.get(browser))
    .join(', ');

  React.useEffect(() => {
    appInsights.trackEvent({
      name: 'Incompatible Browser Used',
      properties: {
        message: 'Incompatible Browser used.',
        browserName,
      },
    });
  });

  return (
    <Result
      status='error'
      title='Incompatible Browser Detected'
      subTitle={`This application is not compatible with ${incompatibleBrowserNames}. We recommend using Google Chrome. Microsoft Edge, or Mozilla Firefox.`}
      extra={[
        <Button key='chrome' href='https://www.google.com/chrome/' target='_blank'>
          Download Google Chrome
        </Button>,
        <Button key='edge' href='https://www.microsoft.com/en-us/edge' target='_blank'>
          Download Microsoft Edge
        </Button>,
        <Button key='firefox' href='https://www.mozilla.org/en-US/firefox/' target='_blank'>
          Download Mozilla Firefox
        </Button>,
      ]}
    />
  );
};

/**
 * The useIncompatibleBrowserView hook returns a HOC to wrap you application. If an incompatible browser is used,
 * an error page will be displayed.
 */
export const useIncompatibleBrowserView = (): IIncompatibleBrowserViewProviderReturnType => {
  // IncompatibleBrowserViewProvider component has one optional prop:
  // incompatibleBrowsers is a list of browsers NOT supported by the application
  const IncompatibleBrowserViewProvider = ({
    incompatibleBrowsers = defaultIncompatibleBrowsers,
    children,
  }: IIncompatibleBrowserViewProviderProps): JSX.Element => {
    // Check to see if the user's browser is present in the list of incompatible browsers provided by the application,
    // but filter out required support browsers
    const showErrorView: boolean = React.useMemo(
      () =>
        incompatibleBrowsers
          .filter((browser: string) => !requiredSupportBrowsers.includes(browser))
          .includes(browserName),
      [incompatibleBrowsers, browserName]
    );

    {
      return showErrorView ? (
        <BrowserView>
          <IncompatibleBrowserResult incompatibleBrowsers={incompatibleBrowsers} />
        </BrowserView>
      ) : (
        <>{children}</>
      );
    }
  };

  return { IncompatibleBrowserViewProvider };
};
