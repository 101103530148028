import * as React from 'react';
import { Input } from 'antd';
import { useFilters, FiltersActionKind } from '../../../../context/filters-context';

const { Search } = Input;

export const SearchBar = (): JSX.Element => {
  const { dispatch } = useFilters();

  const onSearchClick = (value: string): void => {
    dispatch({
      type: FiltersActionKind.setSearchTerm,
      searchTerm: value,
    });
  };

  return (
    <Search
      style={{ minWidth: '50vw' }}
      placeholder='Search by File Name'
      onSearch={onSearchClick}
      enterButton
      allowClear
    />
  );
};
