import React from 'react';
import { useDebounceFn } from 'ahooks';
import {
  EXTRA_SMALL_DEVICE_WIDTH,
  LARGE_DEVICE_WIDTH,
  MEDIUM_DEVICE_WIDTH,
  SMALL_DEVICE_WIDTH,
} from '../util/constants';

interface IDocumentSize {
  width: number;
  height: number;
  isExtraLargeDocument: boolean;
  isLargeDocument: boolean;
  isMediumDocument: boolean;
  isSmallDocument: boolean;
  isExtraSmallDocument: boolean;
}
/**
 * A custom hook to determine the size of the current device being used.
 * Used to create responsive components throughout the app.
 * @returns {IDocumentSize} an object with information about the current device size
 */
export const useDocumentSize = (): IDocumentSize => {
  const [documentSize, setDocumentSize] = React.useState({
    width: document.documentElement.clientWidth,
    height: document.documentElement.clientHeight,
  });

  const { run: changeWindowSize } = useDebounceFn(
    () => {
      setDocumentSize({ width: document.documentElement.clientWidth, height: document.documentElement.clientHeight });
    },
    {
      wait: 150,
    }
  );

  React.useEffect(() => {
    window.addEventListener('resize', changeWindowSize);

    return () => {
      window.removeEventListener('resize', changeWindowSize);
    };
  }, []);

  return {
    ...documentSize,
    isExtraLargeDocument: documentSize.width > LARGE_DEVICE_WIDTH,
    isLargeDocument: documentSize.width <= LARGE_DEVICE_WIDTH,
    isMediumDocument: documentSize.width <= MEDIUM_DEVICE_WIDTH,
    isSmallDocument: documentSize.width <= SMALL_DEVICE_WIDTH,
    isExtraSmallDocument: documentSize.width <= EXTRA_SMALL_DEVICE_WIDTH,
  };
};
