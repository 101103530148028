import * as React from 'react';
import { Layout, Space } from 'antd';
import { useTheme } from '../../../context/theme-context';
import { useDocumentSize } from '../../../hooks/useDocumentSize';

interface IUrlMap {
  [key: string]: string;
}

const { Footer } = Layout;

export const AppFooter = (): JSX.Element => {
  const theme: string = useTheme();
  const { isSmallDocument } = useDocumentSize();

  const TERMS_OF_USE_URLS: IUrlMap = {
    uhc: 'https://www.uhc.com/content/dam/uhcdotcom/en/npp/TOU-UHC-TIC-Public-EN.pdf',
    optum: 'https://www.uhc.com/content/dam/uhcdotcom/en/npp/TOU-Optum-TIC-Public-EN.pdf',
  };

  const PRIVACY_POLICY_URLS: IUrlMap = {
    uhc: 'https://www.uhc.com/content/dam/uhcdotcom/en/npp/OSPP-UHC-TIC-Public-EN.pdf',
    optum: 'https://www.uhc.com/content/dam/uhcdotcom/en/npp/OSPP-Optum-TIC-Public-EN.pdf',
  };

  const getFooterUrls = (): [string, string] => {
    return [TERMS_OF_USE_URLS[theme], PRIVACY_POLICY_URLS[theme]];
  };

  const [termsOfUseUrl, privacyPolicyUrl] = getFooterUrls();

  const additionalInformationPath: string =
    theme === 'uhc' ? '/UHC_Additional_Information.pdf' : '/Optum_Additional_Information.pdf';

  return (
    <Footer
      style={{
        display: 'flex',
        minHeight: '5vh',
        padding: '8px 16px',
        alignItems: 'center',
        justifyContent: isSmallDocument ? 'center' : 'left',
      }}
    >
      <Space
        size={isSmallDocument ? 1 : 'middle'}
        direction={isSmallDocument ? 'vertical' : 'horizontal'}
        style={{ textAlign: 'center' }}
      >
        <span>
          ©{new Date().getFullYear()} {theme === 'optum' ? 'Optum' : 'United HealthCare Services, Inc'}
        </span>
        <Space
          size={isSmallDocument ? ['small', 1] : 'middle'}
          wrap
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <a href={termsOfUseUrl} target='_blank' rel='noreferrer' aria-label='Terms of Use'>
            Terms of Use
          </a>
          <a href={privacyPolicyUrl} target='_blank' rel='noreferrer' aria-label='Privacy Policy'>
            Privacy Policy
          </a>
          <a href={additionalInformationPath} target='_blank' rel='noreferrer' aria-label='Additional Information'>
            Additional Information
          </a>
        </Space>
      </Space>
    </Footer>
  );
};
