import React from 'react';
import { Layout, Space, Typography } from 'antd';
import { useTheme } from '../../../context/theme-context';
import { useDocumentSize } from '../../../hooks/useDocumentSize';
import uhcLogo from '../../../logo/UHC_U-LOGO.png';
import optumLogo from '../../../logo/Optum_LOGO.png';

const { Header } = Layout;

const WrappedLogo = (): JSX.Element => {
  const { isSmallDocument, isExtraSmallDocument } = useDocumentSize();
  const theme: string = useTheme();

  const uhcHeight: number = 40;
  let optumHeight: number = 40;

  if (isSmallDocument) {
    optumHeight = 32;
  }

  if (isExtraSmallDocument) {
    optumHeight = 24;
  }

  return (
    <div
      style={{
        height: theme === 'optum' ? optumHeight : uhcHeight,
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <img
        data-test-id='logo'
        alt='logo'
        style={{
          height: '100%',
        }}
        src={theme === 'optum' ? optumLogo : uhcLogo}
      />
    </div>
  );
};

export const AppHeader = (): JSX.Element => {
  const { isSmallDocument } = useDocumentSize();

  let fontSize: string = '24px';

  if (isSmallDocument) {
    fontSize = '16px';
  }

  return (
    <Header
      className='header'
      style={{
        display: 'flex',
        zIndex: 1,
        alignItems: 'center',
        width: '100%',
        padding: '8px 16px',
        justifyContent: isSmallDocument ? 'center' : 'left',
      }}
    >
      <Space align={'center'} size={16}>
        <WrappedLogo />
        <Typography.Title style={{ fontSize, margin: 0, whiteSpace: 'nowrap' }}>
          Transparency in Coverage
        </Typography.Title>
      </Space>
    </Header>
  );
};
