import * as React from 'react';
import { Space, Layout } from 'antd';
import { SearchBar } from './SearchBar';
import { FileTypeCheckboxes } from './FileTypeCheckboxes';
import { useDocumentSize } from '../../../hooks/useDocumentSize';

const { Content } = Layout;

// Filters are only available on the Optum site, so this component can return null
export const Filters = (): JSX.Element | null => {
  const { isSmallDocument } = useDocumentSize();

  // Conditionally return the JSX if the theme is 'optum', otherwise return null
  return (
    <Content>
      <Space size='middle' direction={isSmallDocument ? 'vertical' : 'horizontal'}>
        <SearchBar />
        <FileTypeCheckboxes />
      </Space>
    </Content>
  );
};
