import React from 'react';
import { Button, List, Space, Result, Alert } from 'antd';
import { WarningFilled } from '@ant-design/icons';
import { ReactPlugin, useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { useBlobStorage, IBlob } from '../../../hooks/useBlobStorage';

const errorMessage: string = `We’re sorry, but your request can’t be completed at this time.
  We’re working to resolve the issue as quickly as possible. Please try again later. Thank you.`;

const partialErrorMessage: string = `We’re sorry but there’s a delay in loading Machine-Readable Files at the moment.
  We’re working to resolve the issue as quickly as possible. Please try again later. Thank you.`;

export const FileList: React.FC = () => {
  const { isLoading, blobList, isError, isPartialError, error, refetch } = useBlobStorage();
  const appInsights: ReactPlugin = useAppInsightsContext();

  React.useEffect(() => {
    // On each render, track a custom event with data about the file list state
    appInsights.trackEvent({
      name: 'FileList Render',
      properties: {
        isLoading,
        blobListLength: blobList?.length,
        isPartialError,
        isError,
        error,
      },
    });
  });

  const onFileClick = (e: React.MouseEvent<HTMLElement>, blob: IBlob): void => {
    // When a MRF link is clicked (downloaded), track the event
    appInsights.trackEvent({ name: 'File Downloaded', properties: { file: blob.name } });
  };

  if (isError) {
    // In the event no MRFs are available due to errors, render an error message
    return (
      <Result
        status='error'
        title='Error fetching files. Please try again soon.'
        subTitle={errorMessage}
        extra={
          <Button type='primary' loading={isLoading} onClick={refetch} data-testid='file-fetching-error'>
            Try Again
          </Button>
        }
      />
    );
  }

  return (
    <>
      {/* In the event some MRFs are available, but not all, render an error message along with the list of available MRFs */}
      {isPartialError && (
        <Alert banner description={partialErrorMessage} type='warning' showIcon icon={<WarningFilled />} />
      )}
      <List
        data-testid='list'
        loading={isLoading}
        size='default'
        dataSource={blobList}
        renderItem={(blob: IBlob, index: number) => (
          <List.Item data-testid={'list-item-' + index}>
            <Space>
              <span>{index + 1}.</span>
              <a
                href={blob.downloadUrl}
                onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => onFileClick(e, blob)}
              >
                {blob.name}
              </a>
            </Space>
          </List.Item>
        )}
      />
    </>
  );
};
