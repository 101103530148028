import React from 'react';
import { Typography } from 'antd';
import { useDocumentSize } from '../../../hooks/useDocumentSize';

const uhcDisclaimer: string = `These files are being provided pursuant to the requirements of the Health Plan
    Transparency in Coverage Rule, and the data herein is intended solely for that purpose. UHC disclaims the fitness of
    the data herein for any other purpose, including use in any third-party database or product. Any such use and any
    liability attached thereto is at the risk of such third party.`;

const optumDisclaimer: string = `These files are being provided pursuant to the requirements of the Health Plan
    Transparency in Coverage Rule, and the data herein is intended solely for that purpose. Optum disclaims the fitness
    of the data herein for any other purpose, including use in any third-party database or product. Any such use and any
    liability attached thereto is at the risk of such third party.`;

const notice: string = `Files are in a JSON format and may contain millions of lines of data and be up to 1
    terabyte (TB) in size. Please consider your system’s capacity and memory when downloading these files.`;

const LineBreak = (): JSX.Element => (
  <>
    <br />
    <br />
  </>
);

export const getDisclaimer = (theme: string): JSX.Element => {
  const mainDisclaimer: string = theme === 'uhc' ? uhcDisclaimer : optumDisclaimer;
  const { isSmallDocument } = useDocumentSize();

  return (
    <Typography.Paragraph
      ellipsis={isSmallDocument ? { rows: 3, expandable: true, symbol: 'more' } : false}
      style={{ margin: 0 }}
    >
      <strong>Disclaimer: </strong>
      {mainDisclaimer}
      <LineBreak />
      <strong>Please note: </strong>
      {notice}
    </Typography.Paragraph>
  );
};
