import { QueryFunction, QueryKey, useQueries, UseQueryResult } from 'react-query';
import axios, { AxiosResponse } from 'axios';
import { useFilters } from '../context/filters-context';
import { getErrorMessage } from '../util/errors';
import { useTheme } from '../context/theme-context';

/**
 * This file contains a React hook used for fetching a list of blobs from Azure Blob Storage.
 * It returns the blob list, loading state, errors, and refetch + download functions.
 */

/**
 * Files are fetched from three different Azure storage accounts owned by three different entities
 * 'origin' corresponds to these entities, UHC, Optum Health, Optum Rx
 * UHC lists its files on the UHC site, Optum Rx and Optum Health have their files combined on the Optum site
 */
export enum origin {
  UHC = 'uhc',
  OH = 'oh',
  ORX = 'orx',
}

export enum ENV {
  DEV = 'dev',
  PROD = 'prod',
  STAGE = 'stg',
}

export interface IBlob {
  name: string;
  downloadUrl: string;
  size: number;
}

interface IBlobQueryResult {
  blobs: IBlob[];
}

interface IBlobQueryParams {
  queryKey: QueryKey;
  queryFn: QueryFunction<IBlobQueryResult>;
}

// Interface defining the return type of the useBlobStorage hook
interface IBlobStorageResult {
  isLoading: boolean;
  isError: boolean;
  isPartialError: boolean;
  error: unknown[];
  blobList: IBlob[];
  refetch: () => void;
}

const env: string = process.env.REACT_APP_RG || ENV.DEV;

const getUrlEnvStringlet = (env: string): string => {
  if (env === ENV.PROD) {
    return '';
  } else {
    return `-${env}`;
  }
};

const urlEnvStringlet: string = getUrlEnvStringlet(env);
const uhcBaseUrl: string = `https://transparency-in-coverage${urlEnvStringlet}.uhc.com/api/v1`;
const optumBaseUrl: string = `https://transparency-in-coverage${urlEnvStringlet}.optum.com/api/v1`;

// A map from each origin to the endpoint where their blob list can be fetched
const blobEndpoints: { [key in origin]: string } = {
  [origin.UHC]: `${uhcBaseUrl}/uhc/blobs/`,
  [origin.OH]: `${optumBaseUrl}/oh/blobs/`,
  [origin.ORX]: `${optumBaseUrl}/orx/blobs/`,
};

const fetchFiles = async (url: string): Promise<IBlobQueryResult> => {
  try {
    const response: AxiosResponse = await axios.get(url);
    return response.data;
  } catch (err) {
    console.log(err);
    throw new Error(getErrorMessage(err));
  }
};

const optumHealthBlobQuery: IBlobQueryParams = {
  queryKey: 'optumHealthBlobs',
  queryFn: () => fetchFiles(blobEndpoints[origin.OH]),
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const optumRxBlobQuery: IBlobQueryParams = {
  queryKey: 'optumRxBlobs',
  queryFn: () => fetchFiles(blobEndpoints[origin.ORX]),
};

const uhcBlobQuery: IBlobQueryParams = {
  queryKey: 'uhcBlobs',
  queryFn: () => fetchFiles(blobEndpoints[origin.UHC]),
};

/**
 * The useBlobStorage hook is a wrapper around the useQueries hook from React Query.
 * useQueries is essentially a wrapper of Promise.all, and returns the result of an array of 'queries' (HTTP GET requests)
 * Based on the theme, we fetch a list of files from the corresponding set of endpoints (1 for UHC, 2 for Optum)
 * Data flattening is done on the results of the useQueries hook in order to make the data more usable/readable
 */
export const useBlobStorage = (): IBlobStorageResult => {
  const theme: string = useTheme();
  const isUHC: boolean = theme === 'uhc';
  const {
    filters: { searchTerm, fileTypes },
  } = useFilters();

  // TODO: Add optumRxBlobQuery
  const queryParams: IBlobQueryParams[] = isUHC ? [uhcBlobQuery] : [optumHealthBlobQuery];
  const blobQueries: UseQueryResult<IBlobQueryResult>[] = useQueries(queryParams);

  const isLoading: boolean = blobQueries.some((result: UseQueryResult<IBlobQueryResult>) => result.isLoading);
  const isError: boolean = blobQueries.every((result: UseQueryResult<IBlobQueryResult>) => result.isError);
  const isPartialError: boolean =
    !isError && blobQueries.some((result: UseQueryResult<IBlobQueryResult>) => result.isError);
  const error: unknown[] = blobQueries.map((result: UseQueryResult<IBlobQueryResult>) => result.error);
  const refetch = (): void => {
    blobQueries.forEach((result: UseQueryResult<IBlobQueryResult>) => result.refetch());
  };

  let blobList: IBlob[] = [];
  // Each element of blobQueries has a list of blobs within its data attribute
  // To merge the blobs into one list, we map the blobQueries to a new array containing their blobs data (possibly undefined)
  // Then we filter out the results with no blobs
  const blobLists: IBlob[][] = blobQueries
    .map((result: UseQueryResult<IBlobQueryResult>) => {
      return result?.data?.blobs;
    })
    .filter((blobs: IBlob[] | undefined): blobs is IBlob[] => {
      return blobs !== undefined;
    });
  // Finally, we merge the blobs into one list
  blobLists.forEach((blobs: IBlob[]) => {
    blobList = blobList.concat(blobs);
  });

  // If a search term filter is present, filter the blobs that match
  if (searchTerm && searchTerm !== '') {
    blobList = blobList?.filter((blob: IBlob) => {
      return blob.name.toLowerCase().includes(searchTerm.toLowerCase());
    });
  }

  // If a file type filter is present, filter the blobs that match
  if (fileTypes.length) {
    blobList = blobList?.filter((blob: IBlob) => {
      return fileTypes.some((fileType: string) => blob.name.includes(fileType));
    });
  }

  return {
    isLoading,
    isError,
    isPartialError,
    error,
    blobList,
    refetch,
  };
};
